import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"

const ContactForm = () => {
  const [send, setSend] = useState(false)
  const [nameSurname, setNameSurname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)

  const resetForm = () => {
    setSend(false)
    setNameSurname("")
    setEmail("")
    setPhone("")
    setMessage("")
    setLegalFirst(false)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("nameSurname", nameSurname)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("legalFirst", legalFirst)

    axios({
      method: "post",
      url:
        "https://konfederacka.zenx.pl/wp-json/contact-form-7/v1/contact-forms/955/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        resetForm()
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(() => {
        console.error("Submit error")
      })
  }

  return (
    <form onSubmit={formSubmit}>
      <div className="row">
        <div className="col-md-4">
          <div className="input-wrapper">
            <input
              type="text"
              id="nameSurname"
              name="nameSurname"
              placeholder="Imię i nazwisko"
              value={nameSurname}
              onChange={e => setNameSurname(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-wrapper">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Adres e-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="input-wrapper">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Numer telefonu"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="input-wrapper">
            <textarea
              rows="6"
              id="message"
              name="message"
              placeholder="Wiadomość"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </div>
        </div>
      </div>
      <label className="checkbox-wrapper">
        <input
          type="checkbox"
          checked={legalFirst}
          onChange={e => setLegalFirst(!legalFirst)}
          required
        />
        <span>
          *Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych
          przez Gabinet podologiczny Agnieszka Kaszuba ul. Konfederacka 11 w
          Krakowie (Administrator danych), w celu marketingu bezpośredniego
          dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie
          z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z
          dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje
          o przetwarzaniu przez nas danych, jak również o prawach osób, których
          dane dotyczą znajdują się w naszej{" "}
          <a href="/polityka-prywatnosci/" target="_blank">
            polityce prywatności
          </a>
          .
        </span>
      </label>
      <div className="submit-wrapper">
        <button
          type="submit"
          className="btn-custom btn-custom--purple"
          disabled={!legalFirst}
        >
          {send === true ? "Wysyłanie.." : "Wyślij"}
        </button>
      </div>
    </form>
  )
}

export default ContactForm
