import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import ContactForm from "../components/contactForm"

const Contact = () => (
  <Layout>
    <SEO title="Kontakt" />

    <GlobalTop title="Kontakt" />

    <section className="contact-info">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="contact-info__tile">
              <div className="contact-info__tile-ico">
                <img
                  className="img-fluid"
                  src={require("../assets/images/contact-ico-1.svg")}
                  alt=""
                />
              </div>
              <h4>Adres</h4>
              <address>
                Manager medyczny Estera Shavriach
                <br />
                Centrum Medyczne Omeda-Med <br />
                <br />
                Ul. Konfederacka 11 <br />
                30-306 Kraków
              </address>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-info__tile">
              <div className="contact-info__tile-ico">
                <img
                  className="img-fluid"
                  src={require("../assets/images/contact-ico-2.svg")}
                  alt=""
                />
              </div>
              <h4>Kontakt</h4>
              <a href="mailto:biuro@omedamed.pl">
              biuro@omedamed.pl
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contact-info__tile">
              <div className="contact-info__tile-ico">
                <img
                  className="img-fluid"
                  src={require("../assets/images/contact-ico-3.svg")}
                  alt=""
                />
              </div>
              <h4>Rejestracja</h4>
              <address>
                od 9:00 do 20:00
                <br />
                <br />
                <a
                  className="btn-custom btn-custom--purple"
                  href="tel:+48600314600"
                >
                  +48 600 314 600
                </a>
              </address>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="contact-form">
      <div className="container">
        <h2 className="section--header text-center">
          <strong>Formularz kontaktowy</strong>
          Wypełnij dane, skontaktujemy się z Tobą
        </h2>
        <ContactForm />
      </div>
    </section>
  </Layout>
)

export default Contact
